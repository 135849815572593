import { acceptHMRUpdate, defineStore } from "pinia"
import { ref, computed, reactive } from "vue"
import api, {
  type Device, type DeviceListParams, type DeviceListFilter,
  type PaginatedMeta, type PaginatedLinks, ModelMemory, ModelColor, Model, ModelGroup
} from "shared/api"
import { useModelGroupsStore, useModelMemoriesStore, useModelColorsStore } from "../stores"
import { useRoute, RouteLocationNormalized, useRouter } from "vue-router"
import { CATALOG_FILTER_NAMES, CATALOG_SORT_BY_KEYS, CATALOG_URL_DATA_KEYS, DEVICES_SORT_BY, ROUTE_NAMES } from "shared/constants"
import {
  CheckboxFilter, CheckboxFilterAvailableValue,
  emitMixpanelEventChooseCatalog, emitMixpanelEventChooseFilter,
  CatalogFiltersTag
} from "shared/lib"
import _ from "lodash"

export const useCatalogStore = defineStore("catalog", () => {
  const route = useRoute()
  const router = useRouter()
  const modelGroupsStore = useModelGroupsStore()
  const modelMemoriesStore = useModelMemoriesStore()
  const modelColorsStore = useModelColorsStore()

  const list = ref<Device[]>([])
  const meta = ref<PaginatedMeta>()
  const links = ref<PaginatedLinks>()

  const modelMemoriesList = ref<ModelMemory[]>([])
  const modelColorsList = ref<ModelColor[]>([])

  // const devicesWithVideo = computed(() => list.value.filter(item => item.Videos.length))

  const filter = reactive<DeviceListFilter>({
    page: 1,
    ParentId: null,
    Models: null,
    SortBy: null,
    SortDirection: null,
    IsFavourite: null,
    MinPrice: null,
    MaxPrice: null,
    IsNew: null,
    Memories: null,
    Colors: null
  })

  const tags = ref<CatalogFiltersTag[]>([])

  const isLoading = ref(true)

  const currentModelGroup = computed(() => {
    return modelGroupsStore.list.find(
      modelGroup => modelGroup.url === `/${route.params?.modelGroupSlug}`
    )
  })

  const currentModel = computed(() => {
    return currentModelGroup.value?.models.find(
      model => model.url === `/${route.params?.modelGroupSlug}/${route.params?.modelSlug}`
    )
  })

  const checkboxFilters = computed(() => {
    const result: CheckboxFilter[] = route.params.modelGroupSlug === "phone-i" ? [{
      name: CATALOG_FILTER_NAMES.SUBSCRIBE,
      availableValues: [
        { name: "Доступен по подписке", slug: "1" }
      ],
      selectedValues: []
    }] :
      []

    result.push(
      {
        name: CATALOG_FILTER_NAMES.MEMORY,
        availableValues: [],
        selectedValues: []
      },
      {
        name: CATALOG_FILTER_NAMES.COLOR,
        availableValues: [],
        selectedValues: []
      }
    )

    return result
  })

  const checkboxFiltersValuesColors = computed(() => checkboxFilters.value.find(item => item.name === "Цвет"))
  const checkboxFiltersValuesMemories = computed(() => checkboxFilters.value.find(item => item.name === "Память"))

  const needDisableInteraction = computed(() => {
    return isLoading.value || !currentModelGroup.value
  })

  function getList() {
    isLoading.value = true
    list.value = []

    const params = getDeviceListParams()
    params.SortBy = filter.SortBy ?? DEVICES_SORT_BY.IS_POPULAR

    return api.devices.getList(params)
      .then(({ data }) => {
        list.value = data.data
        meta.value = data.meta
        links.value = data.links
      })
      .catch(() => {
        list.value = []
      })
  }

  function _initFilter(currentRoute: RouteLocationNormalized) {
    const {
      modelGroupSlug,
      modelSlug
    } = currentRoute.params

    if (modelGroupSlug) {
      const foundedModelGroup = modelGroupsStore.list.find(
        currentModelGroup => currentModelGroup.url === `/${modelGroupSlug}`)

      if (foundedModelGroup) {
        filter.ParentId = foundedModelGroup.id
        filter.Models = null
      }

      if (!!foundedModelGroup && modelSlug) {
        const foundedModel = foundedModelGroup?.models.find(currentModel => currentModel.url === `/${modelGroupSlug}/${modelSlug}`)
        if (foundedModel) {
          filter.Models = [foundedModel.id]
        }
      }
    }

    filter.page = currentRoute.query[CATALOG_URL_DATA_KEYS.PAGE] ? Number(currentRoute.query[CATALOG_URL_DATA_KEYS.PAGE]) : 1

    filter.SortDirection = "asc"
    const queryParamSortBy = currentRoute.query[CATALOG_URL_DATA_KEYS.SORT_BY]
    if (queryParamSortBy) {
      if (queryParamSortBy === CATALOG_SORT_BY_KEYS.POPULAR_FIRST) filter.SortBy = DEVICES_SORT_BY.IS_POPULAR
      else if (queryParamSortBy === CATALOG_SORT_BY_KEYS.CHEAP_FIRST) filter.SortBy = DEVICES_SORT_BY.PRICE
      else filter.SortBy = DEVICES_SORT_BY.IS_POPULAR
    }

    if (currentRoute.query[CATALOG_URL_DATA_KEYS.MIN_PRICE]) {
      filter.MinPrice = Number(currentRoute.query[CATALOG_URL_DATA_KEYS.MIN_PRICE])
    }

    if (currentRoute.query[CATALOG_URL_DATA_KEYS.MAX_PRICE]) {
      filter.MaxPrice = Number(currentRoute.query[CATALOG_URL_DATA_KEYS.MAX_PRICE])
    }

    if (currentRoute.query[CATALOG_URL_DATA_KEYS.MEMORY_SLUGS]) {
      const modelMemoriesSlugs = String(currentRoute.query[CATALOG_URL_DATA_KEYS.MEMORY_SLUGS]).split(",")
      const modelMemories = modelMemoriesStore.list.filter(modelMemory => modelMemoriesSlugs.includes(modelMemory.slug))

      filter.Memories = modelMemories.map(modelMemory => modelMemory.id)
    }

    if (currentRoute.query[CATALOG_URL_DATA_KEYS.COLOR_SLUGS]) {
      const modelColorsSlugs = String(currentRoute.query[CATALOG_URL_DATA_KEYS.COLOR_SLUGS]).split(",")
      const modelColors = modelColorsStore.list.filter(modelColor => modelColorsSlugs.includes(modelColor.slug))

      filter.Colors = modelColors.map(modelColor => modelColor.id)
    }
  }

  function checkFilter(currentRoute: RouteLocationNormalized) {
    const {
      modelGroupSlug,
      modelSlug
    } = currentRoute.params

    if (modelGroupSlug) {
      const foundedModelGroup = modelGroupsStore.list.find(
        currentModelGroup => currentModelGroup.url === `/${modelGroupSlug}`)

      if (!foundedModelGroup) return

      if (foundedModelGroup && filter.ParentId !== foundedModelGroup.id) {
        if (modelSlug) {
          const foundedModel = foundedModelGroup?.models.find(currentModel => currentModel.url === `/${modelGroupSlug}/${modelSlug}`)
          if (!foundedModel) return
        }
      }
    }

    return true
  }

  function getDeviceListParams(): DeviceListParams {
    return Object.fromEntries(
      Object.entries(filter).filter(([, value]) => value !== null)
    )
  }

  async function getModelMemoriesList() {
    if (!filter.ParentId) return

    await api.modelMemories.getList({
      model_group: filter.ParentId,
      models: filter.Models
    })
      .then(({ data }) => {
        modelMemoriesList.value = data
      })
      .catch(() => {
        modelMemoriesList.value = []
      })
  }

  async function getModelColorsList() {
    if (!filter.ParentId) return

    await api.modelColors.getList({
      model_group: filter.ParentId,
      models: filter.Models
    })
      .then(({ data }) => {
        modelColorsList.value = data
      })
      .catch(() => {
        modelColorsList.value = []
      })
  }

  async function init(currentRoute: RouteLocationNormalized) {
    _initFilter(currentRoute)
    _emitMixpanelEventChooseCatalog()
    await _fetchData()
  }

  function _emitMixpanelEventChooseCatalog() {
    let foundedModelGroup: ModelGroup | null = null
    let foundedModel: Model | null = null

    if (filter.ParentId) {
      foundedModelGroup = modelGroupsStore.list.find(
        currentModelGroup => currentModelGroup.id === filter.ParentId) as ModelGroup
    }

    if (filter.Models) {
      foundedModel = foundedModelGroup?.models.find(currentModel => Array.isArray(filter.Models) && currentModel.id === filter.Models[0]) as Model
    }

    emitMixpanelEventChooseCatalog(
      foundedModelGroup,
      foundedModel,
      filter.Memories,
      filter.Colors,
      filter.IsNew,
      filter.SortBy
    )
  }

  async function _fetchData() {
    getList()?.then(async () => {
      await _fillCheckboxFilters()
      await _selectCheckboxFilters()
      _initFiltersTags()
      isLoading.value = false
    })
  }

  // Получаем возможные значения (памяти, цветов) для чекбокс-фильтра
  async function _fillCheckboxFilters() {
    await getModelMemoriesList()
    setCheckboxFilterValue("Память", modelMemoriesList.value)

    await getModelColorsList()
    setCheckboxFilterValue("Цвет", modelColorsList.value)
  }

  async function _selectCheckboxFilters() {
    if (filter.IsNew === 0) {
      const foundCheckboxFilter = checkboxFilters.value.find(checkboxFilter => checkboxFilter.name === CATALOG_FILTER_NAMES.SUBSCRIBE)
      if (!foundCheckboxFilter) return

      foundCheckboxFilter.selectedValues = [{ name: "Доступен по подписке", slug: "1" }]
    }

    if (filter.Memories) {
      const foundCheckboxFilter = checkboxFilters.value.find(checkboxFilter => checkboxFilter.name === CATALOG_FILTER_NAMES.MEMORY)
      if (!foundCheckboxFilter) return

      const modelMemories = modelMemoriesStore.list.filter(modelMemory => Array.isArray(filter.Memories) && filter.Memories.includes(modelMemory.id))
      foundCheckboxFilter.selectedValues = modelMemories
    }

    if (filter.Colors) {
      const foundCheckboxFilter = checkboxFilters.value.find(checkboxFilter => checkboxFilter.name === CATALOG_FILTER_NAMES.COLOR)
      if (!foundCheckboxFilter) return

      const modelColors = modelColorsStore.list.filter(modelColor => Array.isArray(filter.Colors) && filter.Colors.includes(modelColor.id))
      foundCheckboxFilter.selectedValues = modelColors
    }
  }

  const _initFiltersTags = () => {
    if (!route.query[CATALOG_URL_DATA_KEYS.MEMORY_SLUGS] && !route.query[CATALOG_URL_DATA_KEYS.COLOR_SLUGS]) return

    let memories: CatalogFiltersTag[] = []
    let colors: CatalogFiltersTag[] = []

    if (route.query[CATALOG_URL_DATA_KEYS.MEMORY_SLUGS]?.length) {
      const queryMemories = route.query[CATALOG_URL_DATA_KEYS.MEMORY_SLUGS]?.toString().split(",") ?? []
      const availableMemoriesValues = checkboxFiltersValuesMemories.value?.availableValues ?? []

      queryMemories.forEach(slug => {
        const memory = availableMemoriesValues.find(currentMemory => currentMemory.slug === slug) as CatalogFiltersTag
        if (memory) {
          memory.tagName = "Память"
          memory.isChecked = false

          memories.push(memory)
        }
      })
    }

    if (route.query[CATALOG_URL_DATA_KEYS.COLOR_SLUGS]?.length) {
      const queryColors = route.query[CATALOG_URL_DATA_KEYS.COLOR_SLUGS]?.toString().split(",") ?? []
      const availableColorsValues = checkboxFiltersValuesColors.value?.availableValues ?? []

      queryColors.forEach(slug => {
        const color = availableColorsValues.find(color => color.slug === slug) as CatalogFiltersTag
        if (color) {
          color.tagName = "Цвет"
          color.isChecked = false

          colors.push(color)
        }
      })
    }

    setTags([...memories, ...colors])
  }

  // Парсим filter, составляем route и переходим
  function changeRoute() {
    router.push({
      name: !filter.Models?.length ? ROUTE_NAMES.CATALOG_MODEL_GROUP : ROUTE_NAMES.CATALOG_MODEL,
      params: _getParams(),
      query: _getQuery()
    })
  }

  function _getParams() {
    const params: {
      modelGroupSlug?: string,
      modelSlug?: string
    } = {}

    const foundModelGroup = modelGroupsStore.list.find(currentModelGroup => currentModelGroup.id === filter.ParentId)
    params.modelGroupSlug = foundModelGroup?.url.slice(1)
    if (filter.Models?.length) {
      const foundModel = foundModelGroup?.models.find(currentModel => currentModel.id === filter.Models?.at(0))
      params.modelSlug = foundModel?.url.slice(foundModel.url.lastIndexOf("/") + 1)
    }

    return params
  }

  function _getQuery() {
    const query: {
      [CATALOG_URL_DATA_KEYS.PAGE]?: number,
      [CATALOG_URL_DATA_KEYS.SORT_BY]?: string,
      [CATALOG_URL_DATA_KEYS.MIN_PRICE]?: number,
      [CATALOG_URL_DATA_KEYS.MAX_PRICE]?: number,
      [CATALOG_URL_DATA_KEYS.SUBSCRIPTION]?: number,
      [CATALOG_URL_DATA_KEYS.MEMORY_SLUGS]?: string,
      [CATALOG_URL_DATA_KEYS.COLOR_SLUGS]?: string
    } = {}

    if (filter.page && filter.page > 1) {
      query[CATALOG_URL_DATA_KEYS.PAGE] = filter.page
    }

    if (filter.SortBy) {
      if (filter.SortBy === DEVICES_SORT_BY.IS_POPULAR) query[CATALOG_URL_DATA_KEYS.SORT_BY] = CATALOG_SORT_BY_KEYS.POPULAR_FIRST
      else if (filter.SortBy === DEVICES_SORT_BY.PRICE) query[CATALOG_URL_DATA_KEYS.SORT_BY] = CATALOG_SORT_BY_KEYS.CHEAP_FIRST
      else query[CATALOG_URL_DATA_KEYS.SORT_BY] = CATALOG_SORT_BY_KEYS.POPULAR_FIRST
    }

    if (filter.MinPrice) {
      query[CATALOG_URL_DATA_KEYS.MIN_PRICE] = filter.MinPrice
    }

    if (filter.MaxPrice) {
      query[CATALOG_URL_DATA_KEYS.MAX_PRICE] = filter.MaxPrice
    }

    if (filter.IsNew === 0) {
      query[CATALOG_URL_DATA_KEYS.SUBSCRIPTION] = 1
    }

    if (filter.Memories?.length) {
      const modelMemories = modelMemoriesStore.list.filter(modelMemory => Array.isArray(filter.Memories) && filter.Memories.includes(modelMemory.id))
      const modelMemoriesSlugs = modelMemories.map(modelMemory => modelMemory.slug)

      query[CATALOG_URL_DATA_KEYS.MEMORY_SLUGS] = modelMemoriesSlugs.join()
    }

    if (filter.Colors?.length) {
      const modelColors = modelColorsStore.list.filter(modelColor => Array.isArray(filter.Colors) && filter.Colors.includes(modelColor.id))
      const modelColorsSlugs = modelColors.map(modelColor => modelColor.slug)

      query[CATALOG_URL_DATA_KEYS.COLOR_SLUGS] = modelColorsSlugs.join()
    }

    return query
  }

  function setCheckboxFilterValue(name: string, value: CheckboxFilterAvailableValue[]) {
    const index = checkboxFilters.value.findIndex(checkboxFilter => checkboxFilter.name === name)
    if (index != -1) {
      checkboxFilters.value[index].availableValues = value
    }
  }

  function toggleSelectedCheckboxFilterValue(filterName: string, slug: string) {
    const foundCheckboxFilter = checkboxFilters.value.find(checkboxFilter => checkboxFilter.name === filterName)
    if (!foundCheckboxFilter) return

    const foundSelectedValue = foundCheckboxFilter.selectedValues.find(item => item.slug === slug)
    if (foundSelectedValue) {
      _removeSelectedCheckboxFilterValue(filterName, slug)
    } else {
      _addSelectedCheckboxFilterValue(filterName, slug)
    }

    const foundAvailableValue = foundCheckboxFilter.availableValues.find(item => item.slug === slug)
    if (!foundAvailableValue) return

    emitMixpanelEventChooseFilter(filterName, foundAvailableValue.name, filter.IsNew, filter.SortBy)
  }

  function _addSelectedCheckboxFilterValue(filterName: string, slug: string) {
    const foundCheckboxFilter = checkboxFilters.value.find(item => item.name === filterName)
    if (!foundCheckboxFilter) return

    const foundAvailableValue = foundCheckboxFilter.availableValues.find(item => item.slug === slug)
    const foundSelectedValue = foundCheckboxFilter.selectedValues.find(item => item.slug === slug)
    if (!foundAvailableValue || foundSelectedValue) return

    // @ts-ignore
    foundCheckboxFilter.selectedValues.push(_.cloneDeep(foundAvailableValue))
    _addSelectedCheckboxFilterValueToFilter(filterName, foundAvailableValue)
  }

  function _addSelectedCheckboxFilterValueToFilter(filterName: string, foundAvailableValue: CheckboxFilterAvailableValue) {
    filter.page = 1
    if (filterName === CATALOG_FILTER_NAMES.SUBSCRIBE) {
      filter.IsNew = 0
    } else if (filterName === CATALOG_FILTER_NAMES.MEMORY && foundAvailableValue.id) {
      if (!filter.Memories) {
        filter.Memories = [foundAvailableValue.id]
      } else {
        filter.Memories.push(foundAvailableValue.id)
      }
    } else if (filterName === CATALOG_FILTER_NAMES.COLOR && foundAvailableValue.id) {
      if (!filter.Colors) {
        filter.Colors = [foundAvailableValue.id]
      } else {
        filter.Colors.push(foundAvailableValue.id)
      }
    }
  }

  function _removeSelectedCheckboxFilterValue(filterName: string, slug: string) {
    const foundCheckboxFilter = checkboxFilters.value.find(item => item.name === filterName)
    if (!foundCheckboxFilter) return

    const foundSelectedValue = foundCheckboxFilter.selectedValues.find(item => item.slug === slug)
    if (!foundSelectedValue) return

    foundCheckboxFilter.selectedValues = foundCheckboxFilter.selectedValues.filter(item => item.slug !== slug)
    _removeSelectedCheckboxFilterValueFromFilter(filterName, foundSelectedValue)
  }

  function _removeSelectedCheckboxFilterValueFromFilter(filterName: string, foundSelectedValue: CheckboxFilterAvailableValue) {
    if (filterName === CATALOG_FILTER_NAMES.SUBSCRIBE) {
      filter.IsNew = null
    } else if (filterName === CATALOG_FILTER_NAMES.MEMORY && foundSelectedValue.id) {
      if (!filter.Memories || filter.Memories.length <= 1) {
        filter.Memories = null
      } else {
        filter.Memories = filter.Memories.filter(id => id !== foundSelectedValue.id)
      }
    } else if (filterName === CATALOG_FILTER_NAMES.COLOR && foundSelectedValue.id) {
      if (!filter.Colors || filter.Colors.length <= 1) {
        filter.Colors = null
      } else {
        filter.Colors = filter.Colors.filter(id => id !== foundSelectedValue.id)
      }
    }
  }

  function checkIsFilterValueSelected(name: string, slug: string) {
    const foundCheckboxFilter = checkboxFilters.value.find(item => item.name === name)
    if (!foundCheckboxFilter) return

    const foundSelectedValue = foundCheckboxFilter.selectedValues.find(item => item.slug === slug)
    return !!foundSelectedValue
  }

  function resetFilters() {
    filter.page = 1
    filter.IsNew = null
    filter.Memories = null
    filter.Colors = null
    filter.MinPrice = null
    filter.MaxPrice = null

    checkboxFilters.value.forEach(checkboxFilter => checkboxFilter.selectedValues = [])

    tags.value = []
  }

  const setTag = (tag: CatalogFiltersTag) => {
    if (tag.isChecked) {
      tag.isChecked = false
      tags.value.push(tag)
    } else {
      const indexToRemove = tags.value.findIndex(currentTag => currentTag.slug === tag.slug)
      tags.value.splice(indexToRemove, 1)
    }
  }

  const setTags = (newTags: CatalogFiltersTag[]) => tags.value = newTags

  return {
    list,
    meta,
    links,
    filter,
    checkboxFilters,
    checkboxFiltersValuesColors,
    checkboxFiltersValuesMemories,
    tags,
    getList,
    checkFilter,
    init,
    changeRoute,
    currentModelGroup,
    currentModel,
    needDisableInteraction,
    toggleSelectedCheckboxFilterValue,
    checkIsFilterValueSelected,
    resetFilters,
    setTag,
    setTags
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCatalogStore, import.meta.hot))
}
