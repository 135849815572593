const wrapper = [
  "flex",
  "mb-10"
]

const wrapperv2 = "!mb-20"

const image = [
  "w-[104px]",
  "h-[138px]",
  "rounded-xl",
  "mr-3",
  "md:w-[230px]",
  "md:h-[309px]",
  "md:mr-6"
]

const content = "flex-1"

const header = [
  "flex",
  "justify-between",
  "mb-1.5",
  "md:mr-[15px]",
  "md:mb-4"
]

const deviceName = [
  "font-bold",
  "text-primary",
  "leading-[18px]",
  "md:text-[36px]/[43px]",
  "whitespace-nowrap"
]

const trash =  [
  "transition",
  "duration-300",
  "hover:opacity-70"
]

const features = [
  "text-[12px]",
  "mb-3.5",
  "md:grid",
  "md:grid-rows-[repeat(2,_auto)]",
  "lg:grid-cols-[repeat(2,_max-content)]",
  "lg:grid-rows-none",
  "md:gap-x-10",
  "md:text-[16px]",
  "md:mb-5"
]

const featuresv2 = "md:!mb-[46px]"

const price = [
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "md:grid-rows-[auto]",
  "md:grid-cols-[repeat(2,_min-content)]",
  "md:whitespace-nowrap",
  "md:items-center",
  "md:gap-[7px]",
  "md:text-[24px]/[31px]",
  "md:my-4"
]

const pricev2 = "md:!mt-[25px]"

const priceTitle = [
  "text-[16px]/[11px]",
  "font-bold",
  "text-primary",
  "md:text-[24px]"
]

const pricePayment = [
  "text-[32px]/[48px]",
  "font-bold",
  "text-primary",
  "md:text-[24px]"
]

const pricePaymentSpan = "text-[24px]"

const periods = [
  "hidden",
  "md:block"
]

const actualPrice = [
  "text-[16px]/[120%]",
  "font-bold",
  "text-primary",
  "mt-2",
  "md:text-[24px]",
  "md:mt-4"
]

const button = [
  "mt-4",
  "hidden",
  "md:flex"
]

export default {
  wrapper,
  wrapperv2,
  image,
  content,
  header,
  deviceName,
  trash,
  features,
  featuresv2,
  price,
  pricev2,
  priceTitle,
  pricePayment,
  pricePaymentSpan,
  periods,
  actualPrice,
  button
}
