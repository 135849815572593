import { paymentCardTitle, paymentCardButton }  from "shared/lib"

const wrapper = [
  "rounded-xl",
  "shadow-xl",
  "flex",
  "flex-col",
  "items-start",
  "py-4",
  "px-6",
  "md:p-5",
  "bg-icon-primary-on-color"
]

const header = [
  "flex",
  "flex-col",
  "mb-3"
]

const title = [
  ...paymentCardTitle,
  "order-2"
]

const stopper = [
  "h-[17px]",
  "order-1",
  "!py-[4px]",
  "!px-[12px]",
  "mb-[4px]"
]

const steps = [
  "mb-2",
  "w-full"
]

const stepsItem = [
  "mb-2",
  "first:mb-3",
  "last:mb-0"
]

const stepsItemNumber = [
  "inline-block",
  "w-[20px]",
  "h-[20px]",
  "mr-2",
  "text-[14px]/[20px]",
  "font-bold",
  "text-center",
  "rounded-[100px]",
  "bg-bg-dark-blue-disable"
]

const periods = [
  "w-full",
  "md:max-w-[256px]"
]

const total = [
  "w-full",
  "text-left",
  "md:text-center"
]

const titleBig = [
  "text-[36px]/[43px]",
  "font-bold",
  "text-primary"
]

const button = [
  ...paymentCardButton
]

const link = [
  "justify-center",
  "mt-3",
  "inline-block",
  "w-full",
  "md:justify-start",
  "md:ml-[38px]",
  "text-[14px]/[21px]",
  "md:text-[16px]/[24px]"
]

export default {
  wrapper,
  header,
  title,
  stopper,
  steps,
  stepsItem,
  stepsItemNumber,
  periods,
  total,
  titleBig,
  button,
  link
}
