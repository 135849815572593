<script setup lang="ts">
import classes from "./classes"
import { cx } from "cva"
import { computed, PropType } from "vue"
import { Device } from "shared/api"
import { Icon, Button } from "shared/ui"
import { linkStyles, declension } from "shared/lib"
import { storeToRefs } from "pinia"
import { useCitiesStore } from "stores"
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"
import { CatalogDeviceSubscriptionVideo } from "widgets"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_CITIES,
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS
])

// Хардкод на время быстрого эксперимента, нужно будет убрать
const devicesWithLongDelivery = [
  "9dc4966a-5223-49f6-879d-63f2248eef57",
  "090c6213-f920-408f-a499-447376f77acf",
  "94569dcd-2528-4f4d-9c8e-e25d5fe8325d",
  "4a1821f3-ca9e-4454-8767-4acadb77e5c4",
  "8310aa08-6dbf-43e0-a0ee-f8cd55f5697d",
  "59f7a817-c00b-411c-a918-a50d943167db",
  "deec2ae6-f199-4f87-b737-69775563b1bd",
  "5ef60514-48d9-49ef-9d48-c38f0eb746b9",
  "5dedf10a-f080-4b1c-9085-5ca25f9b2778",
  "7834acc9-ee30-4405-86ec-9f85393f74cd",
  "222def80-2255-452d-9eaf-2fff1d571523",
  "c67f4f3c-0ff6-4b43-8757-3312b3d63993",
  "01efff9e-077a-4342-81f9-aea82176e7ff",
  "47f7b758-0036-44b3-95eb-af504ab9cb19",
  "9e4a635b-94f7-41ee-849a-da14350154d2",
  "6f32eb84-36ad-4be8-86d1-03744d4f2c31",
  "0b1d04af-e2ca-486a-a40f-ebeeaf66b7fb",
  "60bb4274-e7bf-46a1-8f4e-7a513571ba33",
  "216d59d4-cf1e-4f50-b340-9f9465979284",
  "2ec7bb06-f1e6-4153-859f-525f8f9e80ab",
  "f810ad20-8883-4c69-af04-3327e01a0fbf",
  "c30f63e6-4130-407e-9c80-64c22b45be4d",
  "2f2a962c-6e77-4e74-a512-d5190469392b",
  "e690aef9-53e5-43b0-95da-400200774dcb",
  "d7daee20-a80b-4776-9453-6b052864b675",
  "fdfc1430-77e2-49ef-a0d6-66ad97a2315a",
  "a80a4fcb-9709-4423-9b2e-0b45bf8d3899",
  "98287bae-5f7e-4b3d-a73d-d962a885fd53",
  "6ede1ce8-5258-4822-b596-5492d0c2f4c2",
  "94477907-f873-4c6d-a2f2-d6842b59393b"
]

const isLongDelivery = devicesWithLongDelivery.includes(props.device.Id)
const deliveryTime = computed(() => {
  if (isLongDelivery) return "12 дней"

  return props.device.IsOnDemand ? "7-10 дней" : "2-3 дня"
})
</script>

<template>
  <div
    v-if="currentCity"
    :class="classes.wrapper"
  >
    <div :class="classes.location">
      <div :class="classes.title">
        Самовывоз
      </div>
      <div :class="classes.locationCity">
        <Icon
          name="common/location"
          width="14"
          height="15"
          :class-name="cx(classes.locationIcon)"
        />
        <Button
          :class="[linkStyles, classes.button, 'mr-1']"
          @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_CITIES)"
        >
          {{ currentCity.name }}
        </Button>
        <Button
          v-if="currentCity.services_count > 1"
          :class="[linkStyles, classes.button, '!font-normal']"
          @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS)"
        >
          {{ currentCity.services_count }}
          {{ declension(currentCity.services_count, ["филиал", "филиала", "филиалов"]) }}
        </Button>
      </div>
      <div :class="classes.locationText">
        <b>Бесплатно</b>, доставим за {{ deliveryTime }}
      </div>
    </div>
    <CatalogDeviceSubscriptionVideo
      :device="device"
      class="md:hidden"
    />
  </div>
</template>
