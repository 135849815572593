<script setup lang="ts">
import classes from "./classes"
import { PropType } from "vue"
import { Button, Stopper } from "shared/ui"
import { Device } from "shared/api"
import {
  YANDEX_METRIKA_GOALS,
  emitYandexMetrika,
  numberSpace,
  emitMixpanelEventCardSubscriptionMoreLinkClicked
} from "shared/lib"
import { useRouter } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"
import { SubscriptionPeriods } from "widgets"

defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  monthlyPayment: {
    type: Number,
    required: false,
    default: 0
  }
})

const router = useRouter()

const redirectToSubscribe = (device: Device) => {
  if (device) {
    emitMixpanelEventCardSubscriptionMoreLinkClicked(device)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_SUBSCRIPTION_MORE_LINK_CLICKED)
  }

  router.push({ name: ROUTE_NAMES.SUBSCRIPTION })
}
</script>

<template>
  <div :class="classes.wrapper">
    <div :class="classes.header">
      <div :class="classes.title">
        По подписке
      </div>
      <Stopper
        :class="classes.stopper"
        color="red"
        text="Выгоднее рассрочки"
      />
    </div>
    <ul :class="classes.steps">
      <li :class="classes.stepsItem">
        <p class="mb-4">
          <span :class="classes.stepsItemNumber">1</span>
          <b>Выберите срок</b>
        </p>
        <SubscriptionPeriods
          :class="classes.periods"
          title=""
        />
      </li>
      <li :class="classes.stepsItem">
        <span :class="classes.stepsItemNumber">2</span>Пользуйтесь без ограничений
      </li>
      <li :class="classes.stepsItem">
        <span :class="classes.stepsItemNumber">3</span>Обменяйте на новый или оставьте себе
      </li>
    </ul>
    <div
      v-if="monthlyPayment"
      :class="classes.total"
    >
      <span :class="classes.titleBig">
        {{ numberSpace(monthlyPayment) }} ₽/мес
      </span>
    </div>
    <Button
      intent="base-primary"
      size="middle"
      :class="classes.button"
      @click="redirectToSubscribe(device)"
    >
      Подробнее о подписке
    </Button>
  </div>
</template>
