<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import classes from "./classes"
import { useSubscriptionStore } from "stores"
import { storeToRefs } from "pinia"
import { YANDEX_METRIKA_GOALS, emitYandexMetrika } from "shared/lib"

const subscriptionStore = useSubscriptionStore()
const { percents, selectedPercent } = storeToRefs(subscriptionStore)

defineProps({
  title: {
    type: String,
    default: "Срок подписки",
    required: false
  }
})

const selectedPeriodIndex = ref<number>(0)

interface Period {
  name: string,
  triggerGoal: Function
}

const periods = computed(() => {
  if (!percents.value) return <Period[]>([])

  const periods = <Period[]>([])

  percents.value.forEach(period => {
    periods.push({
      name: period.Name,
      triggerGoal: () => {}
    } as Period)
  })

  return periods
})

const selectPeriod = (index: number) => {
  if (!Array.isArray(percents.value)) return null

  selectedPercent.value = percents.value[index]
  selectedPeriodIndex.value = index
}

const reachGoal = () => { emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_USLOVIYA_PERIOD) }

const DEFAULT_PERIOD_INDEX = 2
const DEFAULT_PERIOD_INDEX_WITH_HIT = 2

onMounted(() => {
  if (!selectedPercent.value) {
    selectPeriod(DEFAULT_PERIOD_INDEX)
  } else {
    updateSelectedPeriodIndex()
  }
})

watch(selectedPercent, (newSelectedPercent, oldSelectedPercent) => {
  if (newSelectedPercent !== oldSelectedPercent) {
    updateSelectedPeriodIndex()
  }
})

const updateSelectedPeriodIndex = () => {
  if (!Array.isArray(percents.value)) return

  const foundedPercentIndex = percents.value.findIndex(percent => percent.Id === selectedPercent.value?.Id)
  if (foundedPercentIndex !== -1) {
    selectedPeriodIndex.value = foundedPercentIndex
  }
}
</script>

<template>
  <div :class="classes.wrapper">
    <div
      v-if="title"
      :class="classes.title"
    >
      {{ title }}
    </div>
    <div
      v-if="!percents"
      :class="classes.skeletonRectangle"
    />
    <div
      v-else
      :class="classes.periods"
    >
      <div
        :class="classes.periodsList"
        role="periods"
      >
        <div
          v-for="(period, index) in periods"
          :id="`period-${index}`"
          :key="index"
          role="period"
          :aria-selected="index === selectedPeriodIndex"
          :tabindex="index === selectedPeriodIndex ? 0 : -1"
          :class="[classes.periodsItem, index === selectedPeriodIndex ? classes.periodsItemActive : '',
                   index === DEFAULT_PERIOD_INDEX_WITH_HIT ? classes.periodsItemHit : '']"
          @click="selectPeriod(index); reachGoal()"
          @keydown.enter.prevent="selectPeriod(index); reachGoal()"
        >
          <span>{{ period.name.split(' ')[0] }}</span>
          <span v-if="index === selectedPeriodIndex">{{ period.name.split(' ')[1] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
