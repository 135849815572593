export const sectionStyles = [
  "my-[60px]",
  "lg:my-[80px]"
]

export const sectionTitleStyles = [
  "text-[21px]/[25px]",
  "font-bold",
  "text-primary",
  "mb-6",
  "md:text-[36px]/[46px]"
]

export const sectionTitleLargeStyles = [
  "flex",
  "items-center",
  "text-[32px]/[110%]",
  "font-bold",
  "text-primary",
  "mb-9",
  "lg:text-[48px]/[1.2]",
  "lg:mb-6"
]

export const sectionTitleWithSubtitleStyles = [
  "!mb-3",
  "md:!mb-1"
]

export const sectionSubtitleStyles = [
  "mb-9",
  "sm:mb-6"
]

export const pageTitleStyles = [
  "font-bold",
  "text-primary",
  "text-[32px]/[120%]",
  "mb-6",
  "lg:text-[36px]/[120%]",
  "lg:mb-9"
]

export const pageTitleLargeStyles = [
  "text-[32px]/[110%]",
  "font-bold",
  "text-primary",
  "mb-9",
  "lg:text-[48px]/[1.2]",
  "lg:mb-6"
]

export const stretchedLinkStyles = [
  "after:absolute",
  "after:inset-0",
  "after:z-[1]"
]

export const resetAStyles = [
  "no-underline",
  "outline-none"
]

export const linkStyles = [
  "text-accent",
  "hover:text-accent-hover",
  "active:text-accent-active",
  "cursor-pointer",
  "transition",
  "duration-300",
  "no-underline",
  "inline",
  "hover:underline"
]

export const linkUnderlineStyles = [
  "!underline",
  "underline-offset-4"
]

export const skeletonStyles = [
  "bg-gradient-skeleton",
  "bg-bg-main-primary",
  "!bg-[length:40px_100%]",
  "bg-no-repeat",
  "bg-[left_0_top_0]",
  "animate-shine"
]

export const dotsBefore = [
  "flex",
  "before:bg-gradient-check-dots",
  "before:h-px",
  "before:grow",
  "before:inline-block",
  "before:m-0",
  "before:mt-[1em]"
]

export const dotsAfter = [
  "flex",
  "after:bg-gradient-check-dots",
  "after:h-px",
  "after:grow",
  "after:inline-block",
  "after:m-0",
  "after:mt-[1em]",
  "min-w-5"
]

export const paymentCardWrapper = [
  "rounded-xl",
  "shadow-xl",
  "bg-icon-primary-on-color",
  "p-4",
  "cursor-pointer",
  "md:px-5"
]

export const paymentCardTitle = [
  "text-[21px]/[25px]",
  "font-bold",
  "text-primary",
  "md:text-[24px]/[28px]"
]

export const paymentCardButton = [
  "!text-[14px]/[16px]",
  "!font-bold",
  "mt-3",
  "w-full",
  "md:!text-[16px]/[19px]"
]

export const radioInputStyles = [
  "w-[18px]",
  "min-w-[18px]",
  "h-[18px]",
  "border-[3px]",
  "border-solid",
  "border-white",
  "shadow-[0_0_0_2px_#dddddd]",
  "appearance-none",
  "rounded-[50%]",
  "bg-bg-main-secondary",
  "transition",
  "duration-200",
  "cursor-pointer",
  "md:w-[16px]",
  "md:min-w-[16px]",
  "md:h-[16px]",
  "checked:bg-bg-blue-accent"
]

export const sectionSubscriptionDescriptionWrapperStyles = [
  "my-14",
  "md:my-20"
]

export const sectionSubscriptionDescriptionTitleStyles = [
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "gap-2",
  "text-center"
]

export const sectionSubscriptionDescriptionSubtitleStyles = [
  "text-secondary",
  "text-center",
  "mb-2",
  "text-base",
  "font-normal"
]

export const sectionSubscriptionImportantInfoBlockTitleStyles = [
  "text-[16px]/[120%]",
  "md:text-2xl",
  "font-bold",
  "text-primary",
  "mb-3",
  "text-center",
  "md:text-left"
]

export const formGroupStyles = [
  "grid",
  "gap-5",
  "md:gap-2.5"
]

export const inputLabelStyles = [
  "text-[16px]/[1.2]",
  "mb-[5px]",
  "md:text-[24px]"
]

export const fullWidthStyles = [
  "w-screen",
  "relative",
  "inset-x-1/2",
  "-mx-[50vw]"
]

export const socialButtonTelegramStyles = [
  "transition",
  "duration-300",
  "text-primary-on-color",
  "!bg-social-telegram-default",
  "hover:!bg-social-telegram-hover",
  "active:!bg-social-telegram-click"
]

export const socialButtonWaStyles = [
  "transition",
  "duration-300",
  "text-primary-on-color",
  "!bg-social-wa-default",
  "hover:!bg-social-wa-hover",
  "active:!bg-social-wa-click"
]

export const socialButtonVkStyles = [
  "transition",
  "duration-300",
  "text-primary-on-color",
  "!bg-social-vk-default",
  "hover:!bg-social-vk-hover",
  "active:!bg-social-vk-click"
]

export const h1MobileStyles = "text-[32px]/[110%]"

export const h2DesktopStyles = "md:text-[36px]/[120%]"

export const h2MobileStyles = "text-[21px]/[120%]"

export const h3DesktopStyles = "md:text-[24px]/[120%]"

export const h3MobileStyles = "text-[16px]/[120%]"

export const pDesktopStyles = "md:text-[16px]/[125%]"

export const pMobileStyles = "text-[14px]/[120%]"

export const p2MobileStyles = "text-[12px]/[130%]"

export const ppMobileStyles = "text-[10px]/[120%]"
