import { skeletonStyles } from "shared/lib"

const wrapper = [
  "relative",
  "flex",
  "flex-col",
  "justify-between",
  "w-full",
  "h-full",
  "rounded-xl",
  "transition",
  "delay-500",
  "group"
]

const image = [
  "flex",
  "flex-col",
  "h-[250px]",
  "sm:h-[324px]",
  "p-2.5",
  "rounded-xl",
  "bg-cover",
  "bg-no-repeat",
  "bg-center",
  "transition",
  "duration-300",
  "group-hover:brightness-90"
]

const imageSkeleton = [
  ...skeletonStyles,
  "group-hover:brightness-100"
]

const seenTag = [
  "self-start",
  "py-1",
  "px-2",
  "text-[11px]",
  "text-primary-on-color",
  "leading-none",
  "bg-dark-blue-600",
  "rounded-lg"
]

const stoppers = "mt-auto"

const content = [
  "py-2",
  "px-0",
  "md:pt-3",
  "md:px-2.5",
  "md:pb-2"
]

const pricePerMonth = [
  "text-[12px]/[14px]",
  "text-primary",
  "mt-1",
  "md:text-[16px]/[19px]",
  "md:mt-0",
  "md:mb-1"
]

const icons = [
  "absolute",
  "top-[12px]",
  "right-[12px]",
  "flex",
  "flex-col",
  "z-10"
]

const name = [
  "block",
  "font-bold",
  "text-primary",
  "mt-1.5",
  "leading-[14px]",
  "md:mt-4"
]

const nameSkeleton = [
  "w-full",
  "h-[42px]",
  "md:h-12",
  "rounded-xl",
  ...skeletonStyles
]

const text = [
  "block",
  "w-full",
  "overflow-hidden",
  "text-ellipsis",
  "text-primary",
  "text-[12px]/[14px]",
  "font-bold",
  "md:text-[16px]/[19px]"
]

const description = [
  "text-[12px]/[14px]",
  "text-ghost",
  "md:text-[16px]/[19px]",
  "md:mt-1"
]

const descriptionSkeleton = [
  nameSkeleton,
  "h-20",
  "mb-0",
  "md:h-[85px]"
]

const subscriptionPrice = [
  "text-[21px]/[21px]",
  "font-bold",
  "text-primary",
  "md:text-[36px]/[39px]",
  "[&>span]:text-[14px]",
  "md:[&>span]:text-[16px]/[20px]",
  "[&>span:last-child]:text-[14px]/[18px]",
  "[&>span:last-child]:font-semibold",
  "[&>span:last-child>img]:inline-block",
  "[&>span:last-child>img]:pl-1",
  "md:[&>span:last-child]:text-[16px]/[20px]"
]

const subscriptionText = [
  "uppercase",
  "bg-icon-ghost",
  "rounded-lg",
  "py-1",
  "px-2",
  "w-min",
  "text-[8px]/[9px]",
  "font-bold",
  "whitespace-nowrap",
  "text-primary-on-color",
  "md:text-[10px]/[11px]",
  "md:py-[5px]",
  "md:px-2"
]

const priceTitle = [
  "text-[12px]",
  "font-bold",
  "text-primary",
  "md:text-[14px]",
  "md:mb-1.5"
]

const priceRow = [
  "flex",
  "items-center",
  "leading-[25px]",
  "-mt-[3px]",
  "mb-[3px]"
]

const actualPrice = [
  "text-[21px]",
  "font-bold",
  "text-primary",
  "md:text-[34px]",
  "[&>span]:text-[16px]/[19px]"
]

const button = [
  "mt-auto"
]

export default {
  wrapper,
  image,
  seenTag,
  stoppers,
  icons,
  content,
  name,
  text,
  description,
  subscriptionPrice,
  subscriptionText,
  priceTitle,
  priceRow,
  actualPrice,
  pricePerMonth,
  button,
  imageSkeleton,
  nameSkeleton,
  descriptionSkeleton
}
